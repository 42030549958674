import { Avatar, Button, Layout, Menu, Space } from "antd";
//@ts-ignore
import { useHistory, useLocation } from "react-router-dom";
import full_icon from "../../assets/AIESC_Logo_Horizontal_PNG.svg";
import icon from "../../assets/AIESC_Logo_Vertical_PNG.png";
import {
  DashboardOutlined,
  GlobalOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  ContainerOutlined,
  SelectOutlined,
  AppstoreOutlined,
  LeftOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  clearDeckState,
  clearUser,
  queryActivePage,
  setMainSidebarVisible,
} from "../../model";
import { StateTypes } from "../../model/data";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";

const { Sider } = Layout;

const MainMenu = () => {
  const [currentPage, setCurrentPage] = useState(6);

  const isAdmin = useSelector((state: StateTypes) => state.user.isAdmin);
  const collapsed = useSelector(
    (state: StateTypes) => state.global.mainSidebarVisible
  );
  const email = useSelector(
    (state: StateTypes) => state.user.currentUser.email
  );
  const { defaultMapId, currentMapInfo } = useSelector(
    (state: StateTypes) => state.displayEntity
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const menuTopItems = [
    {
      key: 6,
      label: "Home",
      title: "Home",
      icon: <AppstoreOutlined />,
      path: "/home",
    },
    {
      key: 1,
      label: "Map",
      title: "Map",
      icon: <GlobalOutlined />,
      path: currentMapInfo
        ? `/map/${currentMapInfo.map_id}`
        : `/map/${defaultMapId}`,
    },
    {
      key: 2,
      label: "Dashboard",
      title: "Dashboard",
      icon: <DashboardOutlined />,
      path: "/dashboard",
    },
    {
      key: 4,
      label: "Guidance",
      title: "Guidance",
      icon: <ContainerOutlined />,
      path: "/guidance",
    },
    {
      key: 5,
      label: "Analytics",
      title: "Analytics",
      icon: <SelectOutlined />,
      path: "/analytics",
    },
    {
      key: 3,
      label: "Manage Users",
      title: "Access",
      icon: <UserSwitchOutlined />,
      path: "/access",
    },
  ].filter((item) => (isAdmin ? true : item.title !== "Access"));

  const menuBottomItem = [
    // {
    //   key: 4,
    //   label: "Dataset Methodologies",
    //   title: "Dataset Methodologies",
    //   icon: <FilePdfOutlined />,
    //   onClick: () =>
    //     window.open(
    //       "https://leouserfile.s3.eu-central-1.amazonaws.com/Energeo_OCC_Project-LEO_Report_V3.pdf"
    //     ),
    // },
    // {
    //   key: 5,
    //   label: "Help",
    //   title: "Help",
    //   // icon: <QuestionCircleOutlined />,
    //   onClick: () =>
    //     window.open(
    //       "https://leouserfile.s3.eu-central-1.amazonaws.com/User+Guidelines+V1.4.pdf"
    //     ),
    // },
    // {
    //   key: 5,
    //   label: "Help",
    //   title: "Help",
    //   // icon: <QuestionCircleOutlined />,
    //   onClick: () =>
    //     window.open(
    //       "https://leouserfile.s3.eu-central-1.amazonaws.com/User+Guidelines+V1.4.pdf"
    //     ),
    // },
    {
      key: 6,
      label: "Logout",
      title: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => signout(),
    },
  ];

  async function signout() {
    batch(() => {
      dispatch(clearUser());
      dispatch(clearDeckState());
      dispatch(queryActivePage({ pageName: "/", index: 0 }));
    });
    window.sessionStorage.removeItem("currentUser");
    queryClient.removeQueries(["datasets"]);
    history.push("/");
  }

  const handleNavigation = (key: string) => {
    const item: any = menuTopItems.find((item) => item.key === parseInt(key));
    dispatch(queryActivePage({ pageName: item.title, index: item.key }));
    history.push(`${item.path}`);
  };

  const params = useLocation();

  useEffect(() => {
    let pageIndex: any;
    if (params.pathname.includes("/access/")) {
      pageIndex = 3;
    } else {
      pageIndex = menuTopItems.find(
        (item: any) => item.path === params.pathname
      )?.key;
    }
    setCurrentPage(pageIndex);
  }, [menuTopItems, params.pathname]);

  return (
    <Sider
      width={230}
      className="sider"
      onCollapse={() => dispatch(setMainSidebarVisible(!collapsed))}
      trigger={
        !collapsed ? (
          <LeftOutlined
            className="menu-control"
            style={{
              marginTop: 5,
            }}
          />
        ) : (
          <RightOutlined
            className="menu-control"
            style={{
              marginTop: 5,
            }}
          />
        )
      }
      collapsible
      collapsed={collapsed}
      style={{
        position: "relative",
      }}
    >
      <div
        className={collapsed ? "icon" : "logo"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {!collapsed ? (
          <div style={{margin: -50}}>
            <img
              src={full_icon}
              alt="logo"
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          <div style={{margin: "-40px -80px"}}>
            <img src={icon} alt="logo" style={{ width: 350 }} />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: collapsed ? "center" : "flex-start",
          marginBottom: 50,
          marginTop: 50,
          marginLeft: collapsed ? 0 : 40,
        }}
      >
        <Avatar size="large" icon={email ? null : <UserOutlined />}>
          {email.split("@")[0].charAt(0).toUpperCase()}
        </Avatar>
        {collapsed ? null : (
          <div style={{ marginLeft: 20, paddingBottom: 10 }}>
            <p
              style={{
                margin: 0,
                color: "white",
                fontSize: 16,
                textTransform: "capitalize",
              }}
            >
              {email.split("@")[0]}
            </p>
            {/* Content for Edit Profile */}
            {/* <div style={{ display: "flex", marginTop: 5 }}>
              <EditOutlined style={{ color: "white", marginRight: 10 }} />
              <p style={{ margin: 0, color: "white", fontSize: 12 }}>
                Edit Profile
              </p>
            </div> */}
          </div>
        )}
      </div>
      <>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={currentPage ? [currentPage.toString()] : ["6"]}
          style={{
            width: "100%",
            height: "auto",
            justifyContent: "center",
            paddingLeft: collapsed ? 0 : 20,
          }}
          className="top-menu"
          onSelect={({ key }) => handleNavigation(key)}
        >
          {menuTopItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              style={{
                display: "flex",
                justifyContent: collapsed ? "center" : "unset",
              }}
            >
              {collapsed ? null : item.label}
            </Menu.Item>
          ))}
        </Menu>
        <Space size="large" className="bottom-menu">
          {menuBottomItem.map((item) => (
            <Button
              key={item.key}
              icon={item.icon}
              type="text"
              style={{
                background: "#2B323B",
                borderRadius: 4,
                width: collapsed ? "4em" : "10em",
                height: 40,
              }}
              onClick={item.onClick}
            >
              {collapsed ? null : item.title}
            </Button>
          ))}
        </Space>
      </>
    </Sider>
  );
};

export default MainMenu;
